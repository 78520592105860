import React from "react"

import { PublicLayout } from "../../../layouts/public"

const Page = () => {

    const content = {}
    content.htmlTitle = `Thank You`
    content.pageTitle = `Thank You`
    content.pageTagline = `We'll be in touch shortly.`

    return (
        <PublicLayout {...content} />
    )

}

export default Page